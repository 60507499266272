if(document.querySelector('.navigation__link') != null) {
    var tabsLinks = document.querySelectorAll('.navigation__link'),
        tabsContents = document.querySelectorAll('.contents');

    tabsLinks[0].classList.add('active-link');
    tabsContents[0].classList.add('active-content');
    function removeActiveNavLink(elements) {
        elements.forEach(function(link) {
            link.classList.remove('active-link');
        });
    };
    function removeActiveContent(elements) {
        elements.forEach(function(content) {
            content.classList.remove('active-content');
        });
    };
    tabsLinks.forEach(function(link) {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            var linkHref = this.href.substr(this.href.indexOf('#') + 1);
            removeActiveNavLink(tabsLinks);
            removeActiveContent(tabsContents);
            this.classList.add('active-link');
            document.getElementById(linkHref).classList.add('active-content');
        });
    })
}